import React, { Component } from "react"

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = { match: [] }
    this.handleInput = this.handleInput.bind(this)
  }

  handleInput(e) {
    console.log(e.target.value)
    if (e.target.value.length === 0) {
      this.setState(prevState => ({ match: []}))
      return;
    }
    const pattern = new RegExp(e.target.value, 'i');
    console.log(pattern);
    this.setState(prevState => ({
      match: this.props.items.filter((item) => {
        return item.type.match(pattern)
      })
    }))
  }

  render() {
    return (
      <div className={`m-search`}>
        <input type="text" onInput={this.handleInput} placeholder="Jakiego badania szukasz?"/>
        {this.state.match.length > 0 && (
          <div className="m-search__results">
            {this.state.match.map((item, key) => (
              <div className="m-search__result">
                <div dangerouslySetInnerHTML={{__html: item.type}} />
                <div>{item.price} zł</div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default Search
