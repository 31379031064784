import React from "react"
import Pricelist from "./pricelist"

const Packets = ({ heading, image, items }) => (
  <div className={`m-packets`} data-g="grid vertical-center">
    <div data-g="offset-2@lg 2@lg hide show@lg">
      <img src={`/images/diagnostyka/${image}`} alt="ikona pakietów badań" />
    </div>
    <div data-g="4 12@md offset-5@lg 8@lg">
      <Pricelist heading={heading} items={items}/>
    </div>
  </div>
)

export default Packets
