import React from "react"

const map = {
  'Poniedziałek': 'Pon.',
  'Wtorek': 'Wt.',
  'Środa': 'Śr.',
  'Czwartek': 'Czw.',
  'Piątek': 'Pt.',
  'Sobota': 'Sob.',
  'Niedziela': 'Niedz.',
  'Wizyty domowe': 'Wizyty domowe',
}

function replaceWithMap(text) {
  for (const [key, value] of Object.entries(map)) {
    text = text.replace(key, value);
  }
  return text
}

const Figure = ({src, text}) => (
  <div className={`m-figure`}>
    <img src={src} alt="ikonka" />
    <span dangerouslySetInnerHTML={{__html: text}} data-g={"hide show@md"}/>
    <span dangerouslySetInnerHTML={{__html: replaceWithMap(text)}} data-g={"hide@md"}/>
  </div>
)

export default Figure
