import React, { Component } from "react"

class Pricelist extends Component {
  constructor(props) {
    super(props)
    this.state = { dropdown: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      dropdown: !prevState.dropdown
    }))
  }

  render() {
    return (
      <div className={`m-pricelist`}>
        <h3 className="m-pricelist__heading">{this.props.heading}</h3>
        <div className="m-pricelist__card">
          <div className="m-pricelist__items">
            <div className="m-pricelist__headings">
              <div className="type">Rodzaj badania</div>
              <div className="price">Cena</div>
            </div>
            {this.props.items && this.props.items.map((item, key) => (
              <div className={`m-pricelist__item ${this.state.dropdown ? "active" : ""}`} key={key}>
                <div className="type" dangerouslySetInnerHTML={{ __html: item.type }} />
                <div className="price">{item.price} zł</div>
              </div>
            ))}
          </div>
          <div className="m-pricelist__dropdown-switch">
            {this.props.items.length > 3 && (
              <button onClick={this.handleClick}>
                Pokaż {this.state.dropdown ? "mniej" : "więcej"}
                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="8" viewBox="0 0 4 8" fill="none">
                  <path d="M0.5 7L3.5 4L0.5 1" stroke="#00B8BC" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Pricelist
